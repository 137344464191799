import axios from 'axios';

const baseUrl = "https://faizainc-backend.techknowgram.net/api";

export class VideoService {

    getProductsSmall() {
        return axios.get('assets/demo/data/products-small.json').then(res => res.data.data);
    }

    getVideos() {
        return axios.get(baseUrl + '/orders').then(res => res.data);
    }

    createVideo(post) {
        return axios
            .post(baseUrl + '/orders', post)
            .then(response => response)
        // .catch(err => console.log(err))
        // .finally(() => this.loading = false)
    }

    deleteVideo(id) {
        return axios
            .delete(baseUrl + `/orders/${id}`)
            .then(response => response);
    }

    updateVideo(id, post) {
        return axios
            .patch(baseUrl + `/orders/${id}`, post)
            .then((res) => res);
    }

    getProductsWithOrdersSmall() {
        return axios.get('assets/demo/data/products-orders-small.json').then(res => res.data.data);
    }
}