import axios from 'axios';

const baseUrl = "https://faizainc-backend.techknowgram.net/api";

export class PostService {

    getProductsSmall() {
        return axios.get('assets/demo/data/products-small.json').then(res => res.data.data);
    }

    getPosts() {
        return axios.get(baseUrl + '/products').then(res => res.data);
    }

    createPost(post) {
        return axios
            .post(baseUrl + '/products', post)
            .then(response => response)
        // .catch(err => console.log(err))
        // .finally(() => this.loading = false)
    }

    deletePost(id) {
        return axios
            .delete(baseUrl + `/products/${id}`)
            .then(response => response);
    }

    updatePost(id, post) {
        return axios
            .patch(baseUrl + `/products/${id}`, post)
            .then((res) => res);
    }

    getProductsWithOrdersSmall() {
        return axios.get('assets/demo/data/products-orders-small.json').then(res => res.data.data);
    }
}
